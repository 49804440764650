import React, { useState } from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import '../themes/default/main.scss';
import responses from './responses';
import MessageComponent from './MessageComponent/MessageComponent';
import { useMessages } from '../hooks/useMessages';
import { fetchApiResponse, sendToSpreadsheet } from '../hooks/useApi';

const ChatUI = () => {
  const { messages, addMessage, addBotMessage } = useMessages();
  const [isTyping, setIsTyping] = useState(false);
  // APIとのやり取りが終わったかどうかを追跡する状態
  const [apiInteractionFinished, setApiInteractionFinished] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    // HTMLタグを取り除く
    const textOnly = extractTextFromHTML(e);
    setInputValue(textOnly);
  };

  const handleSend = async(value) => {
    setInputValue("");
    setIsTyping(true);
    addMessage(value, "You", "outgoing");
  
    setApiInteractionFinished(false); // APIとのやり取りが始まったことを示す
  
    if (responses[value]) {
      addBotMessage(responses[value]);
    } else {
      await handleApiResponse(value);
    }
    setIsTyping(false);
  };
  
  function extractTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  }
  
  const handleApiResponse = async(value) => {
    // メッセージを送信した後で最後に送信したメッセージを更新
    value = extractTextFromHTML(value);
    // APIからの応答メッセージを取得
    const content = await fetchApiResponse(value);
    if (content === null) {
      setIsTyping(false);
      return;
    }
    // ボットからの応答メッセージを追加
    addBotMessage(content);
    setApiInteractionFinished(true); // APIとのやり取りが終わったことを示す
    setIsTyping(false);

    await sendToSpreadsheet(value, content);  
  };

  return (
  <div style={{ 
    position: "relative", 
    height: "100%", 
    maxWidth:"960px",
    width:"100%", 
    margin:"auto",
    boxShadow:"0px 10px 40px rgba(0,0,0,0.1)",
    "border":"1px solid #F5F8F9", 
    borderRadius:"50px"
    }}>
    <div>
      <ConversationHeader style={{ backgroundColor: "#a28877"}}>
        <ConversationHeader.Content userName="恵比寿ウィメンズクリニック AI問い合わせ"/>
      </ConversationHeader>
      <MainContainer style={{
        display:"flex", 
        flexDirection:"column", 
        height:"80vh"
        }}>
        <ChatContainer style={{
          flex:1, 
          overflowY:"auto", 
          height:"100%"
          }}>
          <MessageList style={{
            maxHeight: "100%", 
            overflowY:"auto"
            }}>
            {messages.map((msg, index) => (
              <>
                <MessageComponent key={index}  msg={msg} apiInteractionFinished={apiInteractionFinished} isLastBotMessage={index === messages.reduce((lastIndex, currentMsg, currentIndex) => currentMsg.sender === "Bot" ? currentIndex : lastIndex, -1)} />
              </>
            ))}
            {isTyping && <TypingIndicator style={{ 
              position: 'sticky', 
              bottom: 0 
              }} content="回答準備中" />}
          </MessageList>
        </ChatContainer>
      </MainContainer>
      <MessageInput
        value={inputValue}
        placeholder="質問を入力してください" 
        onChange={handleInputChange}
        onSend={() => handleSend(inputValue)}
        attachButton={false} 
        style={{flexShrink:1}}/>
     </div>
    </div>
  );
}

export default ChatUI;