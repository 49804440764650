import { useState } from "react";

export const useMessages = () => {
  const [messages, setMessages] = useState([
    {
      message: "ご来訪ありがとうございます。どのようなご用件ですか。",
      sentTime: "just now",
      sender: "Bot",
      // position: "normal",
      direction: "incoming",
    }
  ]);

  const addMessage = (message, sender, direction) => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        message: message,
        sentTime: "just now",
        sender: sender,
        position: "normal",
        direction: direction
      }
    ]);
  };

  const addBotMessage = (message, buttonOptions = null, messageType = "html") => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        message: message,
        type: messageType,
        sentTime: "just now",
        sender: "Bot",
        position: "last",
        direction: "incoming",
        buttonOptions: buttonOptions
      }
    ]);
  };
  return { messages, addMessage, addBotMessage };
};

