import ChatUI from './components/chatui';

function App() {
  return (
    <div className="App">
        <ChatUI />
    </div>
  );
}

export default App;
