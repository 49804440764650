import React from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Message,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import '../../themes/default/main.scss'
import './MessageComponent.css'

const MessageComponent = ({ msg, index, apiInteractionFinished, isLastBotMessage }) => {
  return (
    <React.Fragment>
      <Message model={msg} key={index}>
        {msg.direction !== "outgoing" && 
          <Avatar src={"/icon.png"} name="Joe" />
        }
        {/* <Message.HtmlContent html= {msg.message} /> */}
        <Message.TextContent text={msg.message} />
      </Message>
    </React.Fragment>
  );
};

export default MessageComponent;